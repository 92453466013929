/* These are global antd css overrides */
/* This one in particular is for overriding dropdown styles */
/* Why do it in this global file? Because antd is using React portals to render them */
.ant-dropdown-menu-item-selected,
.ant-select-item-option-selected {
    background: #F2F6FD !important;
    color: #0045a5 !important;
}

ul.ant-dropdown-menu {
    max-height: 400px !important;
    overflow-y: auto !important;
}

/* antd notifications customization */
.ant-notification-notice {
    border-radius: 6px;
    padding: 16px !important;

    &.ant-notification-notice-warning,
    &.ant-notification-notice-error {
        background-color: #DD2E44 !important;
    }
    &.ant-notification-notice-success {
        background-color: #17855E !important;
    }

    & * {
        font-size: 14px !important;
        color: white !important;
    }

    & .ant-notification-notice-with-icon span[role="img"] {
        display: none;
    }
    & .ant-notification-notice-message {
        margin: 0 !important;
    }

    & .ant-notification-notice-close {
        top: 16px !important;
    }
}
